// @ts-ignore
import React, { useRef, useState, useEffect } from "react";
import HomeList from "./HomeList";
import HomePageAbout from "./HomePageAbout";
import { colors } from "../style/global_color";
const HomePage = () => {
  const stopUserSelect = {
    userSelect: "none",
  };
  const welcomeStyle = {
    // position: "relative",
    // zIndex: 2,
  };

  return (
    // <div className="home">
      <div className="sec1">
        <h1
          // @ts-ignore
          style={{
            ...stopUserSelect,
            ...welcomeStyle,
          }}
        >
          Ready to work with someone awesome?
        </h1>
      </div>
      /* <div className="sec2">
        <div className="home_blog">
          <h1
            // @ts-ignore
            style={{ ...stopUserSelect }}
          >
            [ Blog ]
          </h1>
        </div>
        <div className="home_middle">
          <HomeList />
        </div>
      </div> */
    // </div>
  );
};

export default HomePage;
