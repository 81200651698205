/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import { colors } from "../style/global_color";

const Header = () => {
  const stopUserSelect = {
    userSelect: "none",
  };
  return (
    <header className="thisIsHeader">
      <nav>
        <ul>
          <li
            // @ts-ignore
            style={stopUserSelect}
          >
            <NavLink
              className="nav-item"
              to="/"
              // style={({ isActive }) => ({
              //   fontSize: isActive ? "larger" : "100%",
              //   color: isActive ? "palevioletred" : colors.secondary,
              // })}
            >
              Home
            </NavLink>
          </li>
          <li
            // @ts-ignore
            style={stopUserSelect}
          >
            <NavLink
              className="nav-item"
              to="/about"
              // style={({ isActive }) => ({
              //   fontSize: isActive ? "larger" : "100%",
              //   color: isActive ? "palevioletred" : colors.secondary,
              // })}
            >
              About
            </NavLink>
          </li>
          <li
            // @ts-ignore
            style={stopUserSelect}
          >
            <NavLink
              className="nav-item"
              to="/projects"
              // style={({ isActive }) => ({
              //   fontSize: isActive ? "larger" : "100%",
              //   color: isActive ? "palevioletred" : colors.secondary,
              // })}
            >
              Projects
            </NavLink>
          </li>
          <li
            // @ts-ignore
            style={stopUserSelect}
          >
            <a
              className="nav-item"
              href="https://www.linkedin.com/in/keizok/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
